/* Reset
------------------------------------------------------------*/
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
form,
fieldset,
legend,
figure,
figcaption,
button,
input,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}
address,
em {
  font-style: normal;
}

th {
  font-weight: normal;
}
table {
  border: {
    collapse: collapse;
    spacing: 0;
  }
  width: 100%;
}
th {
  text-align: left;
}
main,
aside {
  display: block;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font: {
    size: 100%;
    weight: normal;
  }
}
img,
fieldset {
  border: 0;
}
img {
  border: 0;
  height: auto;
  max-width: 100%;
  object-fit: contain;
  vertical-align: top;
}
li {
  list-style-type: none;
}
select,
button {
  appearance: none;
}
input[type='submit'],
button,
label,
select {
  cursor: pointer;
}
input[type='submit'],
button {
  &:hover {
  }
}
select {
  &::-ms-expand {
    display: none;
  }
}
br {
  line-height: 0;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
