@use "../foundation/variables" as variables;
@use "../foundation/mixin" as mixin;

/*
c-list
*/

.c-list-normal {
  margin: 0 0 0 2em;
}

ul.c-list-normal {
  li {
    &:before {
      content: '\0030fb';
      display: inline-block;
      margin-left: -2em;
      text-align: center;
      width: 2em;
    }
  }
}

ol.c-list-normal li {
  list-style: decimal;
}

/* indent */
.c-list-indent01 li {
  margin: 0 0 0 1em;
  text-indent: -1em;
}

.c-list-indent02 li {
  margin: 0 0 0 2.5em;
  text-indent: -2.5em;
}

.c-dl-form {
  margin: 0 0 20px;

  dt {
    clear: both;
    float: left;
    padding: 33px 0 20px 63px;
    position: relative;
    width: 16em;
    @include mixin.mq-tablet-max {
      float: none;
      padding: 33px 0 0 63px;
      width: auto;
    }

    &.required:before,
    &.any:before {
      color: map-get(variables.$COLOR, white);
      left: 0;
      padding: 2px 10px;
      position: absolute;
      top: 33px;
      @include mixin.fz(11);
    }

    &.required:before {
      background: map_get(variables.$COLOR, strong);
      content: '必須';
    }

    &.any:before {
      background: hsl(0, 0%, 60%);
      content: '任意';
    }
  }

  dd {
    border-bottom: solid 1px hsl(0, 0%, 80%);
    padding: 10px 0 10px 17em;
    @include mixin.mq-tablet-max {
      padding: 10px 0;
    }

    .parts {
      padding: 10px 0;

      textarea,
      input[type='text'],
      input[type='email'] {
        width: 100%;
      }

      &.radio_inline,
      &.check_inline {
        > div {
          display: inline-block;
        }
      }

      &.name {
        display: flex;
        justify-content: space-between;

        > div {
          width: 48.5%;
        }
      }

      &.post,
      &.tel {
        display: flex;

        .hyphen {
          padding: 10px 10px 0 10px;
          @include mixin.mq-sp-wide-max {
            padding: 10px 5px 0 5px;
          }
        }
      }

      &.password {
        input {
          max-width: 300px;
          width: 100%;
          @include mixin.mq-sp-wide-max {
            max-width: none;
          }
        }

        .text {
          padding: 10px 0;
        }
      }
    }
  }

  .validationError {
    color: map_get(variables.$COLOR, strong);
  }
}

.c-list-breadcrumb {
  &-item {
    display: inline;
    @include mixin.mq-tablet-max {
    }

    &:not(:last-child):after {
      content: '\3E';
      margin: 0 8px;
    }

    &.-home {
      &:before {
        display: none;
      }
    }
  }

  &-link {
    &:hover {
    }
  }
}
