@use '../foundation/variables' as variables;
@use '../foundation/mixin' as mixin;

$COMMON_PATH: '../img/common/';

#contents {
}

.l-contents {
  @include mixin.mq-pc-wide-min {
    flex: 1 0 auto;
  }
}
