/* ==========================
 * Foundation
 * ========================== */
@forward "foundation/reset";
@forward "foundation/base";

/* ==========================
 * Components
 * ========================== */
@forward "components/block";
@forward "components/button";
@forward "components/form";
@forward "components/list";
@forward "components/other";
@forward "components/parts";
@forward "components/table";
@forward "components/text";
@forward "components/title";
@forward "components/contents";

/* ==========================
 * Layout
 * ========================== */
@forward "layout/root";
@forward "layout/header";
@forward "layout/footer";
@forward "layout/contents";

/* ==========================
 * Package
 * ========================== */
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital@1&display=swap');