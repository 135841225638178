@use '../foundation/variables' as variables;
@use '../foundation/mixin' as mixin;

#footer {
}

.l-footer {
}
.l-footer-nav {
  background-color: variables.$COLOR_GRAY3;
  display: none;
  @include mixin.fz(18);

  @include mixin.mq-pc-min {
    display: block;
  }
}
.l-footer-nav-list {
  display: flex;
  justify-content: center;
  line-height: 1;
  padding: 15px 0;
}
.l-footer-nav-list__item {
  padding: 0 30px;
}
.l-footer-nav-list__target {
  color: variables.$COLOR_WHITE;
  text-decoration: none;
}

.l-footer-main {
  background: {
    image: url("#{mixin.set_common_path('bg_footer_sp.jpg')}");
    size: cover;
    position: center;
    repeat: no-repeat;
  }
  color: variables.$COLOR_WHITE;
  padding: 60px 0 40px;
  position: relative;
  text-align: center;

  @include mixin.mq-pc-min {
    background-image: url("#{mixin.set_common_path('bg_footer_pc.jpg')}");
    padding: 120px 0 80px;
  }

  &::before {
    background-color: rgba(variables.$COLOR_BLACK, 0.8);
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
.l-footer-main__container {
  max-width: 640px;
}
.l-footer-main__inner {
  position: relative;
  z-index: 1;
}
.l-footer-main__logo {
  margin-bottom: 10px;
  img {
    width: 226px;

    @include mixin.mq-pc-min {
      width: auto;
    }
  }
}
.l-footer-main__text1 {
  margin-bottom: 5px;
  letter-spacing: 0.03em;
}
.l-footer-main__text2 {
  color: variables.$COLOR_GRAY1;
  @include mixin.fz(14);
  letter-spacing: 0.03em;

  @include mixin.mq-pc-min {
    @include mixin.fz(16);
  }
}
.l-footer-main__buttons {
  margin-top: 30px;

  @include mixin.mq-pc-min {
    margin-top: 35px;
  }

  .l-nav-contact-button {
    border-color: variables.$COLOR_GRAY1;
  }
}

.l-footer-main__sns {
  display: block;
  margin-top: 40px;
}

.l-footer-sub {
  background-color: variables.$COLOR_GRAY3;
  color: variables.$COLOR_WHITE;
  padding: 5px 0;

  @include mixin.mq-pc-min {
    padding: 10px 0;
  }
}
.l-footer-sub__inner {
  display: flex;
  justify-content: space-between;
}
.l-footer-sub__list {
  display: none;
  margin-top: 0;

  @include mixin.mq-pc-min {
    display: flex;
  }
}
.l-footer-sub__copyright {
  color: variables.$COLOR_GRAY1;
  @include mixin.fz(12);
  text-align: center;
  width: 100%;

  @include mixin.mq-pc-min {
    width: auto;
  }
}
