@use '../foundation/variables' as variables;
@use '../foundation/mixin' as mixin;

#root {
  overflow: hidden;
}

.l-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
