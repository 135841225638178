@use "variables" as variables;
@use "mixin" as mixin;

@font-face {
  font-family: 'Yu Gothic';
  src: local('Yu Gothic Medium'), local('YuGothic-Medium');
}
@font-face {
  font-family: 'Yu Gothic';
  font-weight: bold;
  src: local('Yu Gothic Bold'), local('YuGothic-Bold');
}

/* link
------------------------------------------------------------*/
a {
  color: map_get(variables.$BASE, color-link);
  text-decoration: underline;


  &:hover {
    text-decoration: none;
  }
}

a,
button {
  &:hover {
    @include mixin.mq-pc-min {
      opacity: 0.7;
    }
  }
}

/* Fonts
------------------------------------------------------------*/
html {
  transition: font-size 0.3s;

  &[data-font='small'] {
    font-size: 12px;
    @include mixin.mq-pc-max {
      font-size: 16px;
    }
  }

  &[data-font='large'] {
    font-size: 18px;
    @include mixin.mq-pc-max {
      font-size: 16px;
    }
  }
}

body {
  color: map_get(variables.$BASE, color);
  line-height: map_get(variables.$BASE, line-height);
  overflow-wrap: break-word;
  -webkit-text-size-adjust: 100%;
  word-wrap: break-word;
  font: {
    family: variables.$FONT_BASE;
    weight: 500;
  }
  @include mixin.fz;

  @include mixin.mq-pc-min {
    @include mixin.fz(18);
  }

  &.noScroll {
    overflow: hidden;
    @include mixin.mq-tablet-max {
      height: 100vh;
      left: 0;
      position: fixed;
      width: 100%;
    }
  }
}

input,
button,
textarea,
select {
  color: map_get(variables.$BASE, color);
  font-family: variables.$FONT_BASE;
  line-height: map_get(variables.$BASE, line-height);
}

img {
  width: 100%;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;

  vertical-align: bottom;
  flex-shrink: 0;
  -ms-interpolation-mode: bicubic;
}

a,
span {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
}