@use "../foundation/variables" as variables;
@use "../foundation/mixin" as mixin;

/*
c-form
*/

/* form
------------------------------------------------------------*/
textarea,
input[type='text'],
input[type='password'],
input[type='email'],
input[type='tel'],
input[type='number'],
input[type='date'],
select {
  border: solid 1px hsl(0, 0%, 67%);
  border-radius: 5px;
  padding: 10px;

  &.error {
    background-color: hsl(0, 100%, 97%);
    border: solid 2px map_get(variables.$COLOR, strong);
  }
}

/* radio
-------------*/
input[type='radio'] {
  @include mixin.accessibilityHidden;

  & + .label {
    cursor: pointer;
    display: inline-block;
    padding: 3px 10px 3px 24px;
    position: relative;
    width: 100%;

    &::before,
    &::after {
      border-radius: 100%;
      content: '';
      position: absolute;
      top: 50%;
      transition: all 0.2s;
    }

    &::before {
      background: hsl(0, 0%, 93%);
      border: 1px solid hsl(0, 0%, 80%);
      height: 16px;
      left: 0;
      margin-top: -9px;
      width: 16px;
    }

    &::after {
      background: map_get(variables.$COLOR, blue);
      height: 10px;
      left: 3px;
      margin-top: -6px;
      opacity: 0;
      transform: scale(0.5);
      width: 10px;
    }

    &:hover {
      &::before {
        background: hsl(0, 0%, 100%);
      }
    }
  }

  &.error {
    & + .label {
      &::before {
        background-color: rgb(255 242 242);
        border: 1px solid map_get(variables.$COLOR, strong);
      }
    }
  }

  &:checked {
    & + .label {
      &::before {
        background: map-get(variables.$COLOR, white);
        border: 1px solid map_get(variables.$COLOR, blue);
      }

      &::after {
        opacity: 1;
        transform: scale(1);
      }
    }
  }
}

/* checkbox
-------------*/
input[type='checkbox'] {
  @include mixin.accessibilityHidden;

  & + .label {
    cursor: pointer;
    display: inline-block;
    padding: 3px 10px 3px 22px;
    position: relative;
    transition: all 0.2s;

    &::before,
    &::after {
      content: '';
      position: absolute;
    }

    &::before {
      background: hsl(0, 0%, 96%);
      border: 1px solid hsl(0, 0%, 80%);
      border-radius: 3px;
      height: 14px;
      left: 0;
      margin-top: -8px;
      top: 50%;
      width: 14px;
    }

    &::after {
      border: {
        bottom: 2px solid map_get(variables.$COLOR, blue);
        left: 2px solid map_get(variables.$COLOR, blue);
      }
      height: 4px;
      left: 3px;
      margin-top: -4px;
      opacity: 0;
      top: 50%;
      transform: rotate(-45deg) scale(0.5);
      width: 8px;
    }

    &:hover {
      &::before {
        background: map-get(variables.$COLOR, white);
      }
    }
  }

  &:checked {
    & + .label {
      &::before {
        background: map-get(variables.$COLOR, white);
        border: 1px solid map_get(variables.$COLOR, blue);
      }

      &::after {
        opacity: 1;
        transform: rotate(-45deg) scale(1);
      }
    }
  }
}

/*==[ contact form7]====================================*/
/* チェックボックス */
input[type="checkbox"] {
  position: absolute;
  opacity: 0;
}

.wpcf7-form-control-wrap {
  display: block;
}

.wpcf7-form-control.wpcf7-checkbox {
  display: flex;
  flex-wrap: wrap;
}

span.wpcf7-list-item {
  position: relative;
  margin-right: 10px !important;
}

.wpcf7-list-item-label {
  color: #333;
  cursor: pointer;
}

.wpcf7-list-item-label:before {
  content: "";
  border: 1px solid #323232;
  border-radius: 5px;
  display: inline-block;
  width: 20px;
  height: 20px;
  position: relative;
  margin-right: 5px;
  margin-bottom: 2px;
  vertical-align: middle;
  cursor: pointer;
  text-align: center;
}

input[type="checkbox"]:checked+.wpcf7-list-item-label:after {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  display: block;
  position: absolute;
  top: 4px;
  left: 3px;
  width: 15px;
  height: 15px;
  /*background: url("省略。チェックアイコン画像のURLを入れる") no-repeat center;*/
  background-size: contain;
}

/* ラジオボタン */
.wpcf7-form-control-wrap {
  display: block;
}

.wpcf7-form-control.wpcf7-radio {
  display: flex;
}

span.wpcf7-list-item {
  position: relative;
  flex-basis: auto;
  width: auto;
  display: inline-block;

  .first {
    margin: 0 !important;
  }
}

.wpcf7-list-item-label {
  color: #555;
  cursor: pointer;
  font-size: 16px
}

input[type="radio"] {
  position: absolute;
  opacity: 0;
  left: 0;
  z-index: 100;
}

/* .wpcf7-list-item-label:before {
  content: "";
  background: #fff;
  border-radius: 100%;
  border: 1px solid #aaa;
  display: inline-block;
  width: 1.4em;
  height: 1.4em;
  position: relative;
  top: -.2em;
  margin-right: 14px;
  vertical-align: middle;
  cursor: pointer;
  text-align: center;
  -webkit-transition: all 250ms ease;
  transition: all 250ms ease;
} */

input[type="radio"]:checked+.wpcf7-list-item-label:before {
  background-color: #FFDBAA;
  box-shadow: inset 0 0 0 2px #fff;
}

input[type="radio"]:checked~.wpcf7-list-item-label:before {
  background-color: #404040;
  box-shadow: inset 0 0 0 2px #fff;
}

.wpcf7c-btn-confirm,
.wpcf7c-btn-back,
.wpcf7-submit {
  border: none;
  color: white;
  font-size: calc(16px + 4 * (100vw - 375px) / 1545);
  padding: 15px;
  margin: 0 auto 30px;
  width: 290px;
  max-width: 100%;

  &:hover {
    opacity: .8;
  }
}

.wpcf7c-btn-confirm,
.wpcf7-submit {
  background-color: #333;
}

.wpcf7c-btn-back {
  background-color: #929292;
}