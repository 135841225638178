@use '../foundation/variables' as variables;
@use '../foundation/mixin' as mixin;

#header {
}

.l-header {
}

.l-header-main {
  background: rgba(variables.$COLOR_BLACK, 0.8);
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;

  @include mixin.mq-pc-min {
    position: absolute;
  }
}

.l-header-main__container {
  max-width: 100%;
}

.l-header-main__inner {
  @include mixin.mq-pc-min {
    padding: 58px 0 18px;
    position: relative;
  }
}

.l-header-main-logo {
  align-items: center;
  display: flex;
  height: 60px;
  width: 181px;

  @include mixin.mq-pc-min {
    height: 90px;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 362px;
  }
}

.l-header-main-logo__target {
}

.l-nav-toggle {
  background: none;
  border: none;
  height: 60px;
  position: fixed;
  right: 0;
  top: 0;
  width: 45px;

  @include mixin.mq-pc-min {
    display: none;
  }
}

.l-nav-toggle__bar {
  background: variables.$COLOR_WHITE;
  height: 2px;
  left: 8px;
  position: absolute;
  top: calc(50% - 1px);
  transition: all 0.3s linear;
  width: 30px;

  &:nth-child(1) {
    margin-top: -10px;
    @at-root .l-nav-toggle[aria-expanded='true'] & {
      margin-top: 0;
      transform: rotate(45deg);
    }
  }
  &:nth-child(2) {
    @at-root .l-nav-toggle[aria-expanded='true'] & {
      left: 30px;
      opacity: 0;
    }
  }
  &:nth-child(3) {
    margin-top: 10px;
    @at-root .l-nav-toggle[aria-expanded='true'] & {
      margin-top: 0;
      transform: rotate(-45deg);
    }
  }
}

.l-nav {
  background-color: variables.$COLOR_BLACK;
  color: variables.$COLOR_WHITE;
  height: calc(100% - 60px);
  left: 0;
  opacity: 0;
  overflow: auto;
  padding: 0 7.5px;
  position: fixed;
  top: 60px;
  transition: opacity 0.3s linear, visibility 0.3s linear;
  visibility: hidden;
  width: 100%;

  @include mixin.mq-pc-min {
    background: none;
    height: auto;
    opacity: 1;
    overflow: visible;
    padding: 0 15px;
    position: static;
    visibility: visible;
  }
}

.l-nav[aria-hidden='false'] {
  opacity: 1;
  visibility: visible;
}

.l-nav-block {
  padding: 30px 0;

  @include mixin.mq-pc-min {
    padding: 0;
  }
}

.l-nav-block:nth-child(n + 2) {
  border-top: 1px solid variables.$COLOR_PRIMARY;

  @include mixin.mq-pc-min {
    border-top: none;
  }
}

.l-nav-contact {
  @include mixin.mq-pc-min {
    display: none;
  }
}

.l-nav-contact__header {
  letter-spacing: 0.06em;
  line-height: 1;
  margin-bottom: 12px;
  padding: 0 15px;
}

.l-nav-contact-buttons {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;

  @include mixin.mq-pc-min {
    margin: 0 -15px;
  }
}
.l-nav-contact-buttons__item {
  padding: 0 5px;
  width: 50%;

  @include mixin.mq-pc-min {
    padding: 0 15px;
  }
}
.l-nav-contact-buttons__item:nth-child(n + 3) {
  margin-top: 10px;
}

.l-nav-contact-button {
  align-items: center;
  border: 1px solid variables.$COLOR_PRIMARY;
  color: variables.$COLOR_WHITE;
  display: flex;
  height: 43px;
  justify-content: center;
  text-align: center;
  text-decoration: none;

  @include mixin.mq-pc-min {
    height: 72px;
    width: 294px;
  }
}

.l-nav-contact-button__inner {
  padding-left: 1.3em;
  position: relative;
}

.l-nav-contact-button__icon {
  font-size: 0.9em;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.l-nav-contact__caption {
  color: variables.$COLOR_GRAY2;
  @include mixin.fz(14);
  line-height: 1;
  margin-top: 12px;
  padding: 0 15px;
}

.l-nav-list {
}
.l-nav-list-main {
  @include mixin.mq-pc-min {
    display: flex;
    justify-content: flex-end;
  }
}
.l-nav-list-main__item {
}
.l-nav-list-main__item:nth-child(n + 2) {
  margin-top: 20px;

  @include mixin.mq-pc-min {
    margin-top: 0;
  }
}
.l-nav-list-main__target {
  background: none;
  border: none;
  color: variables.$COLOR_WHITE;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  display: block;
  line-height: 1;
  padding: 10px 15px;
  text-align: left;
  text-decoration: none;
  width: 100%;

  @include mixin.mq-pc-min {
    text-align: center;

    &:hover {
      background: rgba(variables.$COLOR_BLACK, 0.8);
      opacity: 1;
    }
  }
}

.l-nav-list-main__target--toggle {
  position: relative;
  &::after {
    content: '\f067';
    @include mixin.iconFont();
    @include mixin.fz(12);
    position: absolute;
    right: 15px;
    top: 55%;
    transform: translateY(-50%);

    @include mixin.mq-pc-min {
      display: none;
    }
  }
}
.l-nav-list-main__target--toggle[aria-expanded='true']::after {
  content: '\f068';
}

.l-nav-list-main__text {
  display: inline-block;
  line-height: 1;
  vertical-align: middle;

  @include mixin.mq-pc-min {
    color: variables.$COLOR_GRAY2;
    @include mixin.fz(14);
  }
}
.l-nav-list-main__text-en {
  color: variables.$COLOR_WHITE;
  display: none;
  font-family: variables.$FONT_ALPHA;
  @include mixin.fz(20);
  letter-spacing: 0.06em;

  @include mixin.mq-pc-min {
    display: block;
    margin-bottom: 5px;
  }
}
.l-nav-list-main__text--newWindow {
  padding-right: 15px;
  position: relative;

  @include mixin.mq-pc-min {
    padding-right: 0;
  }

  &::after {
    background: {
      image: url("#{mixin.set_common_path('icon_newwindow.svg')}");
      size: contain;
      repeat: no-repeat;
      position: center;
    }
    content: '';
    height: 10px;
    position: absolute;
    right: 0;
    top: 55%;
    transform: translateY(-50%);
    width: 10px;

    @include mixin.mq-pc-min {
      display: none;
    }
  }
}

.l-nav-list-main-child {
  @include mixin.mq-pc-min {
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translateX(-50%);
    width: 212px;
  }
}

.l-nav-list-main-child__inner {
  background-color: variables.$COLOR_GRAY1;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -7.5px;
  padding: 15px 7.5px;

  @include mixin.mq-pc-min {
    background-color: variables.$COLOR_WHITE;
    padding: 35px 15px;
  }
}
.l-nav-list-main-child__item {
  width: 50%;

  @include mixin.mq-pc-min {
    text-align: center;
    width: 100%;
  }
}
.l-nav-list-main-child__item:nth-child(n + 3) {
  margin-top: 10px;

  @include mixin.mq-pc-min {
    margin-top: 0;
  }
}
.l-nav-list-main-child__item:nth-child(n + 2) {
  @include mixin.mq-pc-min {
    margin-top: 20px;
  }
}

.l-nav-list-main-child__target {
  display: block;
  @include mixin.fz(14);
  text-align: center;
  text-decoration: none;

  @include mixin.mq-pc-min {
    @include mixin.fz(16);
    display: inline-block;
    padding-right: 12px;
    position: relative;
    vertical-align: middle;

    &::after {
      background: {
        image: url("#{mixin.set_common_path('icon_arrow_gray2.svg')}");
        size: contain;
        position: center;
        repeat: no-repeat;
      }
      content: '';
      height: 6px;
      position: absolute;
      right: 0;
      top: 55%;
      transform: translateY(-50%);
      width: 4px;
    }
  }
}

.l-nav-list-main-child[aria-hidden='true'] {
  height: 0;
  opacity: 0;
  overflow: hidden;
  visibility: hidden;
}

.l-nav-list-main__item--toggle {
  .l-nav-list-main__text {
    @include mixin.mq-pc-min {
      padding-right: 12px;
      position: relative;

      &::after {
        background: {
          image: url("#{mixin.set_common_path('icon_arrow_gray2.svg')}");
          size: contain;
          position: center;
          repeat: no-repeat;
        }
        content: '';
        height: 10px;
        position: absolute;
        right: 0;
        top: 65%;
        transform: translateY(-50%) rotate(90deg);
        width: 8px;
      }
    }
  }
}

.l-nav-list-main__item--toggle:hover,
.l-nav-list-main__item--toggle:focus-within {
  position: relative;

  .l-nav-list-main__target {
    @include mixin.mq-pc-min {
      background: rgba(variables.$COLOR_BLACK, 0.8);
      opacity: 1;
    }
  }

  .l-nav-list-main-child {
    @include mixin.mq-pc-min {
      height: auto;
      opacity: 1;
      visibility: visible;
    }
  }
}

.l-nav-list-sub {
  display: flex;
  align-items: center;
  @include mixin.fz(12);
  margin-top: 30px;
  padding: 0 15px;

  @include mixin.mq-pc-min {
    display: none;
  }
}
.l-nav-list-sub__item {
  margin-right: 30px;
}
.l-nav-list-sub__target {
  color: variables.$COLOR_GRAY2;
  line-height: 1;
  padding-left: 10px;
  position: relative;
  text-decoration: none;

  &::before {
    background: {
      image: url("#{mixin.set_common_path('icon_arrow_gray2.svg')}");
      size: contain;
      position: center;
      repeat: no-repeat;
    }
    content: '';
    height: 10px;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 5px;
  }
}

.l-nav-sp-sns {
  @include mixin.mq-pc-min {
    display: none;
  }
}
.l-nav-sp-sns__inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.l-nav-sp-sns__item {
  padding: 0 12px;

  @include mixin.mq-pc-min {
    padding: 0 15px;
  }
}

.l-header-info-pc {
  display: none;

  @include mixin.mq-pc-min {
    display: block;
    position: absolute;
    right: 30px;
    top: 0;
  }
}
.l-header-info-pc__inner {
  display: flex;
}
.l-header-info-pc__item:not(:last-child) {
  margin-right: 15px;
}

.l-header-info-pc-tel {
  align-items: center;
  background-color: variables.$COLOR_BLACK;
  border: 1px solid variables.$COLOR_WHITE;
  border-top: none;
  color: variables.$COLOR_WHITE;
  display: flex;
  height: 40px;
  justify-content: center;
  line-height: 1;
  width: 260px;

  &:hover {
    background-color: variables.$COLOR_WHITE;
    color: variables.$COLOR_BLACK;
    opacity: 1;
  }

  svg {
    vertical-align: middle;
  }
}
.l-header-info-pc-line {
  align-items: center;
  background-color: variables.$COLOR_GRAY2;
  display: flex;
  height: 40px;
  justify-content: center;
  width: 163px;

  &:hover {
    background-color: #00b900;
    opacity: 1;
  }
}
