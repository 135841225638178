@use "../foundation/variables" as variables;
@use "../foundation/mixin" as mixin;

/*
c-block
*/
.c-block-map1 {
  background-color: variables.$COLOR_BLACK;
  height: 264px;
  position: relative;

  @include mixin.mq-pc-min {
    height: 528px;
  }
}
.c-block-map1 iframe {
  height: 100%;
  position: absolute;
  width: 100%;
}
