@use "../foundation/variables" as variables;
@use "../foundation/mixin" as mixin;


/*
modContents
*/
.l-header-pageTitle {
  position: relative;

  h1 {
    color: white;
    font-size: calc(28px + 8 * (100vw - 375px) / 1545);
    position: absolute;
    top: calc(50% + 50px);
    left: 50%;
    transform: translate(-50%, -50%);

    @include mixin.mq-pc-max {
      top: calc(50% + 20px);
    }
  }
}

#pankuzu {
  padding: 20px 0;

  .breadcrumb {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;

    .breadcrumb-item {
      color: #929292;

      &.active {
        font-size: calc(12px + 4 * (100vw - 375px) / 1545);
        text-decoration: underline;
      }

      span,
      a {
        color: #929292;
        font-size: calc(12px + 4 * (100vw - 375px) / 1545);
        text-decoration: none;
        position: relative;
        margin-right: 25px;

        &:after {
          content: "";
          position: absolute;
          top: 50%;
          right: -15px;
          width: 8px;
          height: 8px;
          border-top: 1px solid #929292;
          border-right: 1px solid #929292;
          transform: translateY(-50%) rotate(45deg);
        }
      }
    }
  }
}

.wp-pagenavi {
  display: table;
  margin: 50px auto 20px;
  border-collapse: separate;
  border-spacing: 15px 0;
  a,
  span {
    font-weight: bold;
    height: 40px;
    width: 40px;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    padding: 0 !important;
  }
  a {
    border: 2px solid #dfdfdb !important;
    &:hover {
      border: 2px solid #0060ae !important;
      color: #fff;
      background: #0060ae;
      text-decoration: none;
    }
    &.previouspostslink,
    &.nextpostslink {
      color: #fff;
      border: 2px solid #9b9ea4 !important;
      background: #9b9ea4;
    }
  }
  span {
    border: 2px solid #0060ae !important;
    color: #fff;
    background: #0060ae;
    &.extend {
      color: #9b9ea4;
      background: none;
      border: none !important;
    }
  }
}

.modContentsContainer {
  padding: 50px 0;
}

.modContents {
  a {
    position: relative;
    color: #0068B3;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    &:visited {
      color: #929292;
    }
  }

  &::after {
    content: '';
    display: table;
    table-layout: fixed;
    clear: both;
  }

  .alignleft {
    display: inline;
    float: left;
    margin-right: 1.5em;
    margin-bottom: 1.5em;
  }

  .alignright {
    display: inline;
    float: right;
    margin-left: 1.5em;
    margin-bottom: 1.5em;
  }

  .aligncenter {
    margin-bottom: 20px;
    clear: both;
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  .c_red {
    color: red;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
    border: none;
    line-height: normal;

    &::before,
    &::after {
      content: '';
      display: table;
      table-layout: fixed;
      clear: both;
    }
  }

  ul {
    margin-bottom: 30px;
    list-style: none;

    li {
      padding-left: 30px;
      position: relative;
      word-wrap: break-word;
      margin-bottom: 10px;

      &::before {
        content: '';
        width: 16px;
        height: 16px;
        position: absolute;
        top: 6px;
        left: 2px;
        background: linear-gradient(90deg, rgba(212, 185, 64, 1) 0%, rgba(159, 139, 48, 1) 100%);
      }
    }
    &.list_none {
      padding: 0;
      li {
        padding: 0;
        &::before {
          display: none;
        }
      }
    }
  }

  ol {
    list-style: none;
    counter-reset: ol_li;
    margin-bottom: 30px;

    li {
      padding-left: 30px;
      position: relative;
      word-wrap: break-word;
      margin-bottom: 10px;

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        counter-increment: ol_li;
        content: counters(ol_li, '.');
        font-weight: bold;
      }
    }
  }

  .wp-caption,
  img {
    max-width: 100%;
    height: auto;
  }
  .wp-caption {
    p {
      margin: 0;
    }
  }

  h2 {
    border-top: 1px #000 solid;
    border-bottom: 1px #000 solid;
    color: map_get( variables.$BASE, color );
    font-family: 'Noto Serif JP',
    serif;
    font-size: calc(26px + 6 * (100vw - 375px) / 1545);
    padding: 15px;
    margin-bottom: 40px;
  }

  h3 {
    border-bottom: 1px #000 solid;
    color: map_get( variables.$BASE, color );
    font-size: calc(22px + 6 * (100vw - 375px) / 1545);
    padding: 0 15px;
    margin-bottom: 40px;
  }

  h4 {
    color: map_get( variables.$BASE, color );
    font-size: calc(18px + 6 * (100vw - 375px) / 1545);
    padding-left: 20px;
    margin-bottom: 40px;
    border-left: 6px solid #333;
  }

  h5 {
    color: map_get( variables.$BASE, color );
    font-size: calc(16px + 6 * (100vw - 375px) / 1545);
    font-weight: 700;
    margin-bottom: 40px;
  }

  h6 {
    color: map_get(variables.$BASE, color);
    font-size: calc(16px + 4 * (100vw - 375px) / 1545);
    font-weight: bold;
    margin-bottom: 20px;
  }

  p {
    color: #323232;
    margin-bottom: 30px;
  }

  input[type='text'],
  input[type='email'],
  input[type='url'],
  input[type='password'],
  input[type='search'],
  input[type='number'],
  input[type='tel'],
  input[type='range'],
  input[type='date'],
  input[type='month'],
  input[type='week'],
  input[type='time'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='color'],
  textarea {
  }

  input[type='text']:focus,
  input[type='email']:focus,
  input[type='url']:focus,
  input[type='password']:focus,
  input[type='search']:focus,
  input[type='number']:focus,
  input[type='tel']:focus,
  input[type='range']:focus,
  input[type='date']:focus,
  input[type='month']:focus,
  input[type='week']:focus,
  input[type='time']:focus,
  input[type='datetime']:focus,
  input[type='datetime-local']:focus,
  input[type='color']:focus,
  textarea:focus {
  }

  iframe {
    width: 100%;
  }

  #gallery {
    margin-bottom: 30px;
  }
  .gallery {
    .gallery-item {
      padding: 2px;
      img {
        border: none !important;
      }
    }
  }

  .btn_pdf {
    display: inline;
    padding-right: 40px;
    background: url('../img/common/bg_pdf.png') no-repeat right 10px center;
  }

  .btn_doc {
    display: inline;
    padding-right: 40px;
    background: url('../img/common/bg_doc.png') no-repeat right 10px center;
  }

  .btn_xls {
    display: inline;
    padding-right: 40px;
    background: url('../img/common/bg_xls.png') no-repeat right 10px center;
  }

  .btn_ppt {
    display: inline;
    padding-right: 40px;
    background: url('../img/common/bg_ppt.png') no-repeat right 10px center;
  }

  .btn_link {
    display: inline;
    padding-right: 40px;
    background: url('../img/common/bg_blank.png') no-repeat right 10px center;
  }

  	.btn01 {
  	  background-color: white;
  	  border: 1px #333 solid;
  	  color: #333 !important;
  	  display: inline-block;
  	  padding: 15px;
  	  text-align: center;
  	  text-decoration: none;
  	  width: 240px;
  	  max-width: 100%;

  	  &:hover {
  	    background-color: #333;
  	    color: white !important;
  	    text-decoration: none;
  	    opacity: 1;
  	  }
  	}

  	.btn02 {
      color: #333 !important;
      padding-right: 30px;
      position: relative;

  	  &:hover {
  	    opacity: .7;
        text-decoration: none;
  	  }

      &::after {
        content: "";
        display: inline-block;
        width: 8px;
        height: 8px;
        margin: 0 10px;
        border-top: 2px solid #333;
        border-right: 2px solid #333;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-25%) rotate(45deg);
      }
  	}

  .wpcf7-field-group {
    br {
      &:nth-of-type(2) {
        display: none;
      }
    }
    input[type='number'] {
      width: 40px;
    }
    input[type='text'] {
      width: 120px;
    }
  }
  .wpcf7-field-group-add,
  .wpcf7-field-group-remove {
    padding: 8px 18px;
    max-width: 50px;
    margin-right: 0;
    &:before {
      content: none;
    }
  }

  .scroll_wrap {
    width: 100%;
  }

  table {
    max-width: 100%;
    width: 100%;
    margin-bottom: 30px;
    border-collapse: collapse;
    border-spacing: 5px;

    /* table-layout: fixed; */
    th,
    td {
      padding: 20px;

      @media screen and (max-width: 576px) {
        padding: 10px;
      }
    }

    thead {

      th,
      td {
        text-align: left;
        font-weight: bold;
        background: #EFECE7;
        border-top: 4px #707070 dotted;
        border-bottom: 4px #707070 dotted;
        color: map_get(variables.$BASE, 'color');
      }
    }

    tbody {
      tr {

        &:nth-child(odd) {
          background-color: #f2f2f2;
        }

        &:nth-child(even) {
          background-color: white;
        }

      }
      th {
        border: 1px #dfdfdf solid;
        font-weight: bold;
        color: map_get(variables.$BASE, 'color');
        width: 20%;

        @media screen and (max-width: 576px) {
          width: 30%;
        }
      }

      td {
        border: 1px #dfdfdf solid;
        color: map_get(variables.$BASE, 'color');
        width: 80%;

        @media screen and (max-width: 576px) {
          width: 70%;
        }
      }
    }

    .wp-caption {
      p {
        margin: 0;
      }

      width: auto !important;

      img {
        max-width: 100%;
        padding: 0;
        width: auto;
        height: auto;
      }
    }

    &.tbl_none {
      margin: 0;
      padding: 0;
      display: table;
      table-layout: fixed;

      th,
      td {
        background: none;
        border: none;
        vertical-align: top;
        text-align: left;
        margin: 0;
        padding: 10px;
      }
    }

    &.tbl_fix {
      table-layout: fixed;
    }

    &.tbl_responsive {
      table-layout: fixed;

      tr {

        &:nth-child(odd) {
          background-color: white;
        }

        &:nth-child(even) {
          background-color: white;
        }

      }
    }
  }
}
@media screen and (max-width: 767px) {
  .modContents {
    .alignleft,
    .alignright {
      display: block;
      float: none;
      margin: 0 auto 20px auto;
    }
    .scroll_wrap {
      margin-bottom: 30px;
      overflow: auto;
      -ms-overflow-style: -ms-autohiding-scrollbar;
      -webkit-overflow-scrolling: touch;
      &:before {
        content: '→スクロールできます。';
      }
    }
    .tbl_scroll {
      margin-bottom: 0;
      max-width: 767px !important;
      width: 767px !important;
    }
    .tbl_responsive,
    .tbl_responsive thead,
    .tbl_responsive tfoot,
    .tbl_responsive tbody,
    .tbl_responsive tr,
    .tbl_responsive th,
    .tbl_responsive td {
      display: block;
      width: 100% !important;
    }
    .list_1 li {
      width: 100%;
    }
    .list_1 li:nth-child(even) {
      margin-left: 0;
    }
  }
}

ul.modListJump {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  li {
    padding: 0;
    margin: 0;
    &:before {
      display: none;
    }
    a {
      display: inline-block;
      color: #0461b0;
      position: relative;
      padding: 10px 15px 10px 35px;
      &:before {
        content: '';
        position: absolute;
        top: 13px;
        left: 15px;
        width: 10px;
        height: 10px;
        border-top: 2px solid map_get( variables.$BASE, color );
        border-right: 2px solid map_get( variables.$BASE, color );
        transform: rotate(135deg);
      }
    }
  }
}

ul.modListTable {
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  align-content: stretch;
  flex-wrap: wrap;
  li {
    background: white;
    color: map_get( variables.$BASE, color );
    width: calc( 100% / 4 - 10px );
    padding: 0;
    margin-bottom: 20px;
    border: 5px solid #FBE2EC;
    @include mixin.mq-tablet-max {
      width: calc( 100% / 2 - 10px );
    }
    @include mixin.mq-tablet-min {
      width: 100%;
    }
    &:before {
      display: none;
    }
    a {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      color: map_get( variables.$BASE, color );
      text-decoration: none;
      height: 100%;
      width: 100%;
      font-weight: bold;
      padding: 15px 30px 15px 20px;
      &:before {
        content: '';
        position: absolute;
        top: calc( 100% / 2 - 4px );
        right: 15px;
        width: 10px;
        height: 10px;
        border-top: 1px solid map_get( variables.$BASE, color );
        border-right: 1px solid map_get( variables.$BASE, color );
        transform: rotate(45deg);
      }
    }
  }
}

ul.modListSns {
  .snsicon1,
  .snsicon2,
  .snsicon3 {
    position: fixed;
    right: 5px;
    img {
      width: 35px;
    }
  }
  .snsicon1 {
    bottom: 210px;
  }
  .snsicon2 {
    bottom: 170px;
  }
  .snsicon3 {
    bottom: 130px;
  }
}


.card-wrap {
  position: relative;
  margin-bottom: 60px;

  &::after {
    content: "";
    display: inline-block;
    width: 30px;
    height: 30px;
    border-bottom: 6px solid #929292;
    border-right: 6px solid #929292;
    position: relative;
    bottom: -25px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
  }

  &:last-child {
    margin-bottom: 30;
  }

  &.last {
    &::after {
      display: none;
      content: "";
    }
  }
}

.card {
  background-color: #dfdfdf;
  padding: 30px 60px;
  margin-left: 30px;
  position: relative;

  @media screen and (max-width: 576px) {
    padding: 40px 20px 20px;
    margin-left: 0;
  }

  p {
    margin-bottom: 0;
  }

  .card-badge {
    background-color: #333;
    color: white;
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
    font-family: 'Libre Baskerville',
    serif;
    font-size: calc(24px + 14 * (100vw - 375px) / 1545);
    padding: 5px;
    width: 60px;
    height: 60px;
    position: absolute;
    top: 50%;
    left: -30px;
    transform: translateY(-50%);

    @media screen and (max-width: 576px) {
      width: 40px;
      height: 40px;
      top: 0;
      left: 0;
    }

  }
}